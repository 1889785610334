import React from "react"
import { Link, navigate } from "gatsby"
import CardsIcons from "../../static/img/Payments.png"
import { useLocation } from "@reach/router"
import Logo from "../../static/img/logo_black.png"

const Footer = () => {
  const location = useLocation()

  return (
    <div className="bg-blue">
      <div className="container mx-auto flex justify-between footer">
        <div className="p-5">
          <div className="">
            <a onClick={() => navigate(`/`)}> <img src={Logo} width="200px" className="menu-logo" alt="logo" /></a>
          </div>
        </div>
        <div className="p-5">
          <div
            className={`font-bold color-home color-${
              location.pathname.split("/")[1]
            } text-lg mb-4 pb-3 border-b-1 lg:pr-16 `}
          >
            Contact
          </div>
          <div className="mb-1">
            <Link
              to="/contact"
              className="text-black hover:text-gray-800"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="p-5">
          <div
            className={`font-bold color-home color-${
              location.pathname.split("/")[1]
            } text-lg mb-4 pb-3 border-b-1 lg:pr-16 `}
          >
            Legal stuff
          </div>

           <div className="mb-1">
            <Link
              to="/privacy-policy"
              className="text-black hover:text-gray-800"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="mb-1">
            <Link
              to="/terms-and-conditions"
              className="text-black hover:text-gray-800"
            >
              Terms and Conditions
            </Link>
          </div>
        </div>

        <div className="p-5">
          <div>
            {" "}
            <img
              src={CardsIcons}
              className="cards-icons"
              alt="icons of cards"
            />
          </div>
        </div>
      </div>
      <div className="p-5 text-center  bg-dark-blue" style={{fontWeight: "500"}} >
        ©2025 Boostik. All rights reserved
      </div>
    </div>
  )
}

export default Footer
